.ty {
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.header {
  height: 51px;
}

.container {
  height: calc(100% - 51px);
  display: flex;
  flex-direction: column;
  .content {
    padding: 24px;
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h3 {
      margin-top: 32px;
      font-weight: 700;
      font-size: 22px;
      span {
        color: var(--red);
      }
    }
    .joining {
      margin-top: 32px;
      p {
        font-size: 12px;
        color: #000000;
        opacity: 0.4;
      }
      h5 {
        font-size: 16px;
        color: #00b87f;
      }
    }
  }
  .controls {
    padding: 24px;
    .navToProfile {
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        flex: auto;
        margin-left: 16px;
      }
      .arrow {
        font-size: 22px;
      }
      font-size: 18px;
      width: 100%;
      border-radius: 10px;
      padding: 18px 16px;
      background-color: var(--red);
      border: 0;
      outline: 0;
      color: var(--white);
      &:active {
        background-color: var(--light-pink);
      }
    }
  }
}
