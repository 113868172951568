.body {
  width: 100%;
  background-color: var(--start-page--background);
  display: flex;
  align-items: center;
  //   justify-content: center;
  flex-direction: column;
  overflow: unset;
  .women {
    z-index: 1;
    height: 52vh;
    position: absolute;
    right: 0;
    bottom: 16vh;
  }
  height: 100vh;
}

.title {
  font-family: "Inter-600";
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: var(--white);
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 26rem;

  padding-top: 5.8rem;
}

.subtitle {
  width: calc(100% - 2rem);
  padding: 4rem 0.3rem;
  p {
    font-family: "Inter-600";
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--white);

    width: 21.7rem;
  }
}

.button_group {
  width: calc(100% + 4.8rem);

  div {
    width: 22.7rem;
    height: 23.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.mainContent {  
  width: 100%;
  background-color: var(--start-page--background);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: unset;
  padding: 0 2.4rem;
}

.topRatedBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 50px;
  background: #82A79A;
  .topRatedImage {
    width: 4.3rem;
    height: 4.3rem;
    margin-right: 0.8rem;
  }
  .topRatedTitle {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #FFFFFF;
  }
}

.headerMenu {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
    .burgerContainer {
      .burgerIconWrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #FFFFFF;
      }
      .dropdownMenu {
        display: none;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        background-color: #FFFFFF;
        width: max-content;
        padding: 24px;
        margin-top: 1.3rem;
        border: 0;
        border-radius: 16px;
        box-shadow: 0 6px 16px rgb(0 0 0 / 5%);
        position: absolute;
        .dropdownLink {
          cursor: pointer;
          text-decoration: none;
          margin-bottom: 2.7rem;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: #000000;
        }
        .dropdownLinkContactUs {
          cursor: pointer;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          text-decoration: none;
          p {
            margin-left: 1rem;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: #000000;
          }
        }
      }
      .dropdownMenuOpen {
        display: flex;
      }
    }
  .transpilotImage {
    width: 224px;
    height: 22px;
  }
}
