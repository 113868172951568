.plan {
  border: 2px solid var(--gray-disabled);
  border-radius: 14px;
  .container {
    display: flex;
    flex-direction: column;
    .mostPopular {
      border-radius: 10px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      padding: 4px;
      background-color: var(--gray-disabled);
      color: #fff;
      text-align: center;
      font-size: 12px;
    }
  }
  .planContent {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .price {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--gray-disabled);
      .priceText {
        display: flex;
        flex-direction: column;
        h5 {
          font-size: 14px !important;
          font-weight: 700;
          color: #8e8e8e;
        }
        p {
          font-size: 12px;
          span {
            text-decoration: line-through;
            color: var(--gray-disabled);
          }
        }
      }
    }
    .priceLabel {
      position: relative;
      .triangle {
        border-radius: 4px;
        border-bottom-left-radius: 8px;
        position: absolute;
        top: 50%;
        left: calc(42px / 1.41 / 2 * -1);
        left: -10px;
        height: calc(42px / 1.41);
        width: calc(42px / 1.41);
        transform: translateY(-50%) rotate(45deg);
        background-color: var(--gray-disabled);
        z-index: -1;
      }
      .priceText {
        border-radius: 8px;
        z-index: 2;
        height: 42px;
        width: 81px;
        background-color: var(--gray-disabled);
        color: #828282;
        display: flex;
        align-items: flex-start;
        padding-top: 4px;
        .dollar {
          font-size: 14px;
        }
        .zero {
          font-size: 32px;
          line-height: 32px;
        }
        .pricePerDay {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 0;
          .perDay {
            font-size: 10px;
            margin-top: -4px;
          }
        }
      }
    }
  }
}

.checked {
  border-color: var(--main-pink);
  .container {
    .mostPopular {
      background-color: var(--main-pink);
    }
    .price {
      color: #000;
      .priceText {
        color: #000;
        h5 {
          color: #000;
        }
        p {
          span {
            color: #828282;
          }
        }
      }
    }
    .priceLabel {
      .priceText {
        background-color: var(--light-pink);
        color: #000;
      }
      .triangle {
        background-color: var(--light-pink);
      }
    }
  }
}
