.questions {
  height: 100%;
}

.header {
  height: 51px;
}
.content {
  padding: 16px;
  padding-bottom: 32px;

  h3 {
    font-weight: 700;
    text-align: center;
  }

  .personalized {
    margin-top: 8px;
  }

  .personalizedContent {
    text-align: center;
    h3 {
      margin: 0 auto;
      width: 70%;
      font-size: 24px !important;
      span {
        color: var(--main-pink);
      }
    }

    p {
      margin-top: 16px;
      color: #828282;
    }
    h4 {
      margin-top: 16px;
      font-size: 18px;
      font-weight: 700;
      span {
        color: var(--main-pink);
      }
    }
  }

  .less {
    text-align: center;
    margin-top: 24px;
    p {
      color: #828282;
    }
    h2 {
      margin: 16px 0;
      font-size: 22px !important;
      font-weight: 700;
      text-decoration: underline;
      text-underline-offset: 4px;
      color: var(--main-pink);
    }
    .chart {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .benefits {
    margin-top: 24px;
    .benefitsContent {
      ul {
        padding: 0;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        li {
          line-height: 16px;
          display: flex;
          align-items: flex-start;
          gap: 8px;
          list-style: none;
          font-size: 16px;
          font-weight: 500;
          //   white-space: nowrap;
        }
      }
    }
  }
  .choosePlan {
    margin-top: 24px;
    .plans {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .control {
      margin-top: 42px;
      p {
        text-align: center;
        color: #828282;
        margin-top: 24px;
      }
    }
  }

  .manyUsers {
    margin-top: 32px;
    background-color: #fff;
    padding: 4px;
    box-shadow: 0px 2px 12px 0px #2d2d2d0a;
    border-radius: 16px;
    .manyUsersBorder {
      border-radius: 12px;
      border: 1px solid #4fc902;
      background-color: #4fc90214;
      padding: 12px;
      text-align: center;
      p {
        margin-top: 12px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .moneyback {
    margin-top: 32px;
    background-color: var(--gray-bg);
    border-radius: 16px;
    padding: 18px 16px;
    h4 {
      font-size: 18px;
      font-weight: 700;
    }
    .paragraph {
      margin-top: 8px;
      position: relative;
      p {
        color: #828282;
      }
      .garant {
        text-align: end;
        display: block;
        right: 0;
        bottom: 0;
      }
    }
  }

  .gradFrame {
    z-index: 1;
    border-radius: 16px;
    border: 2px solid var(--main-pink);
    padding: 16px 32px;
    background-color: var(--light-pink);
  }

  .footer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .footerItem {
      width: 100%;
      h4 {
        font-size: 16px;
        font-weight: 700;
      }
      p {
        font-size: 14px;
        color: #828282;
        a {
          color: var(--main-pink);
          text-decoration: none;
        }
      }
    }
  }
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  width: 300px;
  margin: auto;
}

.paypage {
  display: block;
}

.hide {
  display: none;
}
