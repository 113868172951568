.btnAnswer {
  text-align: start;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 4vw;
  padding: 16px;
  background-color: var(--gray-bg);
  color: var(--primairy-text);
  &:active {
    background-color: var(--light-pink);
  }
}
