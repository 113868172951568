.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0px 24px;
    h1 {
        margin-top: 4.8rem;
        font-weight: 700;
        font-size: 5rem;
        line-height: 6.1rem;
        text-align: center;
        color: #000000;
    }
    .form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 5.6rem;
    }
    input {
        width: 100%;
        height: 6.7rem;
        padding: 24px 22px;
        margin-top: 3rem;
        background-color: #F5F5F5;
        border: 0;
        border-radius: 20px;
        color: #7D7D7D;
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.9rem;
        outline: none;
    }
    .submitButton {
        cursor: pointer;
        width: 100%;
        margin-top: 3rem;
        height: 5.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--red);
        border-radius: 20px;
        border: 0px;
        color: white;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
    .forgotPassword {
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        margin-top: 3.9rem;
        color: #9e9e9e;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
    }
    .description{
        margin-top: 10rem;
        margin-bottom: 3.3rem;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
        text-align: center;
        color: #9E9E9E;
        a {
            color: #9E9E9E;
        }
    }
    .errorMessage {
        margin-top: 0.5rem;
        align-self: flex-end;
        text-align: right;
        color: #FF0000;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
    }
    .errorInput {
        border: 1px solid #FF0000;
    }
}
.modalTitle {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.9rem;
    text-align: center;
    color: #000000;
}
.modalDescription {
    margin-top: 1.3rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-align: center;
    color: #000000;
}
.modalSubmitButton {
    border: 0px;
    margin-top: 2.2rem;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    text-transform: uppercase;
    color: var(--white);
    width: 100%;
    height: 7.3rem;
    background-color: var(--red);
    border-radius: 20px;
}
.closeModal {
    cursor: pointer;
    position: absolute;
    top: -4rem;
    right: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 50%;
    background-color: var(--white);
}
  