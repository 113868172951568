.questions {
  height: 100%;
}

.header {
  height: 51px;
}
.content {
  height: calc(100% - 51px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .loading {
    margin-top: 24px;
    h3 {
      text-align: center;
      margin-bottom: 40px;
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .trusted {
      font-size: 16px !important;
      font-weight: 600;
    }
    .feedback {
      border-radius: 16px;
      padding: 20px;
      box-shadow: 0px 4px 40px 0px #2d2d2d14;
      text-align: center;
      h5 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        margin: 8px 0 12px 0;
      }
      p {
        font-size: 12px;
        opacity: 0.5;
      }
    }
  }
}

.loadingBarsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.loadingBar {
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  .percentage {
    display: flex;
    align-items: center;
    gap: 12px;
    .progressText {
      font-size: 14px;
    }
  }
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--main-pink);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

.progressBarContainer {
  position: relative;
  background-color: #f8f8f8;
  height: 10px;
  border-radius: 999px;
}

.progressBar {
  border-radius: 999px;
  height: 10px;
  background-color: var(--main-pink);
  transition: 0.4s ease;
}

.disabled {
  .title {
    opacity: 0.5;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
