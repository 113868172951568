.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0rem 1.6rem;
    .specialOfferWrapper {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        border: 1px solid #E4E4E4;
        border-radius: 20px;
        padding: 0.7rem 1rem;
        margin-top: 2.1rem;
        width: 34.3rem;
        .getProgramButton {
            margin-left: 0.7rem;
            background: var(--red);
            border-radius: 20px;
            padding: 1.8rem 1.2rem;
            min-width: 15.3rem;
            font-weight: 800;
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: var(--white);
            border: 0px;
            cursor: pointer;
            animation: pulse_button 2s infinite;
        }
        .specialOfferTimerWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            .specialOfferText {
                margin-right: 0.5rem;
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 1.9rem;
                color: #7D7D7D;
            }
            .timer {
                font-weight: 700;
                font-size: 2rem;
                line-height: 2.4rem;
                color: #000000;
            }
            .hideTimer {
                display: none;
            }
        }
    }

    .personalizedPlanWrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        .personalizedPlanTitle {
            max-width: 27.3rem;
            font-weight: 600;
            font-size: 3rem;
            line-height: 3.6rem;
            text-align: center;
            color: #000000;
        }
        .personalizedPlanSubtitle {
            margin-top: 2rem;
            max-width: 32rem;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.2rem;
            text-align: center;
            color: #000000;
        }
    }
    
    .selectPlanImage {
        margin-top: 5rem;
    }
    
    .selectPlanContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 3.5rem;
        .selectPlanTitle {
            font-weight: 600;
            font-size: 3rem;
            line-height: 3.6rem;
            text-align: center;
            color: #000000;
        }
        .soldOutBlock {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            margin-top: 2rem;
            width: 35rem;
            height: 12.2rem;
            border: 1px solid #E5E5E5;
            border-radius: 20px;
            padding: 0rem 2rem;
            .soldOutTitle {
                margin-top: 2rem;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 2.2rem;
                text-transform: uppercase;
                color: #807B70
            }
            .soldOutContentWrapper {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                margin-top: 3.1rem;
                .soldOutDate {
                    font-weight: 700;
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    text-transform: uppercase;
                    color: #807B70
                }
                .soldOutPrice {
                    color: #807B70;
                    display: flex;
                    justify-content: flex-start;
                    text-align: left;
                    font-weight: 700;
                    .dollarSign {
                        font-size: 12px;
                        line-height: 15px;
                    }
                    .integerFromPrice {
                        font-size: 30px;
                        line-height: 36px;
                    }
                }
            }
        }
    }
    .archiveResultsContainer {
        margin-top: 3.6rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        .archiveResultsTitle {
            width: 30.8rem;
            font-weight: 600;
            font-size: 3rem;
            line-height: 3.6rem;
            text-align: center;
            color: #000000;
        }
        .archiveResultsSubtitle {
            margin-top: 2.5rem;
            width: 31.8rem;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1.9rem;
            text-align: center;
            color: #000000;
            
        }
        .beforeAfterWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            width: 35rem;
            height: 36rem;
            border: 1px solid #D9D9D9;
            border-radius: 20px;
            padding: 2rem 0rem;
            margin-top: 2rem;
            .tabWrapper {
                display: flex;
                width: 100%;
                padding: 0rem 5.95rem;
                justify-content: space-between;
                margin-bottom: 1.4rem;
                .tabText {
                    font-weight: 400;
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    color: #7D7D7D;
                }
            }
            .beforeAfterName {
                margin-top: 1rem;
                margin-left: 2.65rem;
                align-self: flex-start;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 2.2rem;
                text-align: center;
                color: #000000;
            }
            .beforeAfterComment {
                margin-top: 0.8rem;
                margin-left: 2.65rem;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
            }
        }
    }
    .advantageBlockContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 3.6rem;
        padding: 2rem;
        .advantageTitle {
            width: 30.8rem;
            font-weight: 600;
            font-size: 2.6rem;
            line-height: 3.1rem;
            text-align: center;
            color: #000000;
            span {
                color: var(--red);
            }
        }
    }
    .ticketContainer {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        background-image: url("../../assets/ticketBg.png");
        width: 35rem;
        height: 40.3rem;
        .ticketTitle {
            margin-top: 5rem;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.2rem;
            text-align: center;
            color: #000000;
        }
        .ticketPlan {
            margin-top: 1rem;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.2rem;
            text-align: center;
            text-transform: uppercase;
            color: #000000;
        }
        .ticketPrice {
            margin-top: 2.7rem;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.4rem;
            color: #807B70;
            text-align: center;
            span {
                font-weight: 700;
                font-size: 70px;
                line-height: 85px;
                text-transform: uppercase;
                color: #000000;
            }
        }
        .ticketSave {
            margin-top: 1.8rem;
            text-align: center;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.4rem;
            color: #807B70;            
        }
        .ticketButton {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28.9rem;
            height: 5.7rem;
            background: var(--red);
            border-radius: 20px;
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase; 
            color: var(--white);
            border: 0px;
            margin: 5rem auto 0rem;
            animation: pulse_button 2s infinite;
        }
    }
    .expectContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-top: 3.6rem;
        .expectTitle {
            width: 33rem;
            font-weight: 600;
            font-size: 3.0rem;
            line-height: 3.6rem;
            text-align: center;
            color: #000000;
            span {
                color: var(--red);
            }
        }
        .expectSubtitle {
            margin-top: 2rem;
            font-weight: 600;
            width: 35rem;
            font-size: 1.8rem;
            line-height: 2.2rem;
            text-align: center;
            color: #000000;
            span {
                color: var(--red);
            }
        }
        .expectDescription {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        .expectList {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            width: 35rem;
            background: var(--white);
            border: 3px solid var(--red);
            border-radius: 20px;
            padding: 2rem;
            .expectListItem {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;
                margin-bottom: 2.2rem;
                &:last-child {
                    margin-bottom: 0rem;
                }
                .expectListItemIndex {
                    margin-right: 1.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 3rem;
                    height: 3rem;
                    background: var(--red);
                    border-radius: 50%;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: var(--white);
                }
                .expectListItemDescription {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: #000000;
                }
            }
        }
    }
    .graphContainer {
        margin-top: 3.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .graphTitle {
            width: 32rem;
            font-weight: 600;
            font-size: 3.0rem;
            line-height: 3.6rem;
            color: #000000;
            text-align: center;
            span {
                color: var(--red);
            }
        }
        img {
            margin-top: 3rem;
        }
    }
    .benefitsContainer {
        margin-top: 3.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .benefitsTitle {
            width: 24.6rem;
            text-align: center;
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
            color: #000000;
        }
    }
    .reviewsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 3.6rem;
        .reviewsTitle {
            width: 33rem;
            font-weight: 600;
            font-size: 3.0rem;
            line-height: 3.6rem;
            color: #000000;
            text-align: center;
            span {
                color: var(--red);
            }
        }
        .reviewsWrapper {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            margin-top: 2rem;
            .reviewItem {
                padding: 1.6rem;
                margin-top: 1rem;
                width: 35rem;
                height: 13.6rem;
                background: #F6F4F0;
                border-radius: 20px;
                .reviewHeader {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: row;
                    .reviewReitingWrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-direction: column;
                        margin-left: 1.2rem;
                        .reviewName {
                            font-weight: 700;
                            font-size: 1.8rem;
                            line-height: 2.2rem;
                            color: #000000;
                        }
                        .starsWrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-direction: row;
                            margin-top: 0.8rem;
                            img {
                                margin-right: 0.4rem;
                            }
                        }
                    }
                }
                .reviewComment {
                    margin-top: 0.8rem;
                    font-weight: 400;
                    font-size: 1.4rem;
                    line-height: 1.7rem;
                    color: #000000;
                }
            }
        }
    }
    .questionsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 3.6rem;
        .questionsTitle {
            font-weight: 600;
            font-size: 3rem;
            line-height: 3.6rem;
            color: #000000;
        }
        .questionsSubtitle {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1.9rem;
            text-align: center;
            color: #000000;
        }
    }
    .guaranteedContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2rem;
        width: 35rem;
        height: 22rem;
        background: #F6F4F0;
        border-radius: 20px;
        margin-top: 2rem;
        .guaranteedTitle {
            width: 20rem;
            font-weight: 700;
            font-size: 2rem;
            line-height: 2.4rem;
            text-align: center;
            color: #000000;
        }
        .guaranteedDescription {
            margin-top: 2.2rem;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.2rem;
            color: #847F75;
        }
    }
    .issueContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 3.6rem;
        .issueTitle {
            font-weight: 600;
            font-size: 3rem;
            line-height: 3.6rem;
            color: #000000;
        }
        .issueButton {
            margin-top: 1rem;
            margin-bottom: 6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33.6rem;
            height: 7.3rem;
            background: #9D4545;
            border-radius: 20px;
            border: 0px;
            font-weight: 800;
            font-size: 1.8rem;
            line-height: 2.2rem;
            text-transform: uppercase;
            color: var(--white);
        }
    }
}

.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5.6rem;
}

.supportModalInput {
    width: 100%;
    height: 6.7rem;
    padding: 24px 22px;
    margin-top: 1rem;
    border: 1px solid rgba(125, 125, 125, 0.2);
    border-radius: 20px;
    color: #7D7D7D;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    outline: none;
}

.supportModalTextArea {
    resize: none;
    min-width: 100%;
    min-height: 14.3rem;
    max-width: 100%;
    max-height: 14.3rem;
    padding: 24px 22px;
    margin-top: 1rem;
    border: 1px solid rgba(125, 125, 125, 0.2);
    border-radius: 20px;
    color: #7D7D7D;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    outline: none;
}

.modalTitle {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.9rem;
    text-align: center;
    color: #000000;
}

.modalSubmitButton {
    border: 0px;
    margin-top: 2.2rem;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    text-transform: uppercase;
    color: var(--white);
    width: 100%;
    height: 7.3rem;
    background: #9D4545;
    border-radius: 20px;
}
.closeModal {
    cursor: pointer;
    position: absolute;
    top: -4rem;
    right: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 50%;
    background-color: var(--white);
}

.errorMessage {
    margin-top: 0.5rem;
    align-self: flex-end;
    text-align: right;
    color: #FF0000;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}
.errorInput {
    border: 1px solid #FF0000;
}


.popUpContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    .popUpWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 30.3rem;
        height: 50.2rem;
        background-image: url('../../assets/popupBg.png');
        .closePopUp {
            cursor: pointer;
            margin-top: 1rem;
            margin-left: 1rem;
            align-self: flex-start;
        }
        .popUpTitle {
            width: 24.7rem;
            margin-top: 25rem;
            font-weight: 600;
            font-size: 2.4rem;
            line-height: 2.9rem;
            text-align: center;
            color: #000000;
        }
        .popUpSubtitle {
            margin-top: 1.5rem;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1.9rem;
            text-align: center;
            color: #000000;
            width: 24.7rem;
        }
        .popUpButton {
            cursor: pointer;
            margin-top: 2.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26.3rem;
            height: 7.3rem;  
            background: var(--red);
            border-radius: 20px;
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            color: var(--white);
            border: 0px;
        }
    }
}

@keyframes pulse_button {
    0% {
        box-shadow: 0 0 0 0 rgb(53 107 97 / 70%);
    }
    50% {
        box-shadow: 0 0 0 10px rgb(53 107 97 / 0%);
    }
    100% {
        box-shadow: 0 0 0 0 rgb(53 107 97 / 0%);
    }
}