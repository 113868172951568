.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.4rem;
  width: 100%;
  overflow: hidden;
}

.age_button_group {
  width: calc(100% - 4.8rem);

  div {
    margin-top: 3.4rem;
    height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.yes_no_button_group {
  width: calc(100% - 4.8rem);

  div {
    margin-top: 4.5rem;
    height: 15.1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.rejuvenate_button_group {
  margin-top: 4.5rem;
  width: calc(100% - 4.8rem);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  div {
    width: 18rem;
    height: 15.1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.title__women {
  height: 17.4rem;
  margin-bottom: 2.9rem;
  img {
    height: 100%;
  }
}

.absolute_women {
  img {
    z-index: 1;
    height: 52vh;
    position: absolute;
    left: 0;
    top: 24rem;
  }
}

.button_group__tone,
.button_group__problems {
  margin-top: 2.1rem;
  width: calc(100% - 4.8rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10vh;
  button {
    margin-bottom: 1.6rem;
  }
}

.button_group__priority {
  width: calc(100% - 4.8rem);
  margin-top: 4.5rem;
  height: 48.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12vh;
}

.priority_women {
  img {
    z-index: 1;
    height: 47vh;
    position: absolute;
    left: 0;
    top: 18rem;
  }
}

.sliderWrapper {
  width: 90%;
  margin-top: 2.5rem;
  .sliderImageWrapper {
    background-color: white;
    &:focus {
      outline: 0;
      outline-offset: 0;
    }
    .sliderImage {
      margin: 0 auto;
    }
  }
}

.button__bar {
  display: inline-block;
  margin: 1.6rem auto 0;
  padding: 0;
  text-align: center;
  & :global(li.slick-active) button {
    width: 23px;
    background-color: black;
  }
  li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 0.6rem;
    padding: 0;
    button {
      border: none;
      background: #D9D9D9;
      color: transparent;
      cursor: pointer;
      display: block;
      height: 5px;
      width: 8px;
      border-radius: 7.5px;
      &:hover {
        background: black;
        outline: 0;
      }
    }
    &:focus{
      outline: none;
    }
  }
}

.slick-slide div {
  outline: none;
 }
