.content {
  background-color: #fff;
  padding: 0 16px;
  height: 100%;
  button {
    outline: 0;
    border: 0;
    background-color: transparent;
  }

  .skip {
    font-size: 16px;
    color: #2d2d2d;
    opacity: 0.5;
  }

  display: flex;
  align-items: center;
  .logo {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .title {
    font-size: 18px;
    color: #ff5a7f;
    margin: 0;
  }
}

.initial {
  .logo {
    justify-content: flex-start;
  }
}
