.progressBar {
    padding: 0 16px;
}


.header {
    height: 51px;
  }
  .content {
    height: calc(100% - 51px);
  }