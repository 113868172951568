.planBlock {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  width: 35rem;
  height: auto;
  padding-bottom: 2rem;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  .planBlockTitleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: var(--red);
    border-radius: 20px 20px 0px 0px;
    .planBlockTitle {
      padding: 1rem 0rem;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      color: var(--white);
    }
  }
  .planBlockTitleWrapperNotActive {
    background: #c7c4bc;
  }
  .planBlockContentWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
    padding: 0rem 2rem;
    .planBlockDate {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      color: #000000;
    }
    .planBlockDateNotActive {
      color: #807b70;
    }
    .planBlockDiscount {
      margin-top: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5.7rem;
      height: 3rem;
      background: var(--red);
      border-radius: 5px;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: var(--white);
    }
    .planBlockDiscountNotActive {
      background: #c7c4bc;
    }
    .planBlockPrice {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      font-weight: 700;
      .dollarSign {
        font-size: 12px;
        line-height: 15px;
      }
      .integerFromPrice {
        font-size: 30px;
        line-height: 36px;
      }
    }
    .planBlockPriceNotActive {
      color: #807b70;
    }
  }
}
.getPlanButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 35rem;
  height: 7.3rem;
  background: var(--red);
  font-weight: 800;
  border-radius: 20px;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  color: var(--white) !important;
  border: 0px;
  animation: pulse_button 2s infinite;
  text-decoration: none;
}
.descriptionPlanText {
  margin-top: 2rem;
  width: 31rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
  color: #9e9e9e;
}

.planTrialDescrtiption {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-top: 2.4rem;
}

@keyframes pulse_button {
  0% {
    box-shadow: 0 0 0 0 rgb(53 107 97 / 70%);
  }
  50% {
    box-shadow: 0 0 0 10px rgb(53 107 97 / 0%);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(53 107 97 / 0%);
  }
}
