.page {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    padding-top: 3.4rem;
    margin-bottom: 12vh;
    max-width: 400px;
    margin: 0 auto;
    .headerWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      h1 {
        color: #436a61;
        margin-left: -10%;
        text-align: center;
        flex: 1 1 90%;
      }
      .headerButton {
        z-index: 10;
        cursor: pointer;
        height: 100%;
        border: none;
        outline: none;
        background-color: white;
        img {
          width: 1.4rem;
        }
      }
    }

    p {
      font-size: 14px;
    }
    ol {
      font-size: 14px;
    }
    ul {
      font-size: 14px;
    }
  }