.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.4rem;
  width: 100%;
  margin-bottom: 12vh;
}

.subTitle {
  width: 29.5rem;
  margin-top: 1.8rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  color: #000000;
}

.cardWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background: #F6F4F0;
  border-radius: 20px;
  padding: 0.8rem;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  img {
    margin-right: 2rem;
  }
  .cardInfoWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    .cardName {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: #000000;
      margin-bottom: 0.8rem;
    }
    .cardJob {
      max-width: 18.7rem;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000000;
    }
  }
}

.graphWrapper {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.programListWrapper {
  margin-top: 2.4rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .programListItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-bottom: 2rem;
    .programListItemIndex {
      margin-right: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 27px;
      background: #9D4545;
      border-radius: 50%;
    }
    .programListItemName {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000000;
    }
  }
}