html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --red: #ff5a7f;
  --white: #ffffff;
  --black: #000000;
  --color-button: #f6f3ed;
  --color-area: #efede8;
  --button-image--border: #d9d9d9;
  --button-image--background: rgba(67, 106, 97, 0.06);
  --disabled-button-color: #ffdee5;

  --main-pink: #ff5a7f;
  --light-pink: #ffdee5;
  --gray-bg: #f2f2f2;
  --primary-text: #000;
  --secondary-text: #828282;

  --gray-disabled: #eaeaec;

  overflow: auto;
  height: auto;
  width: 100%;
  --start-page--background: #dbc3bf;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Inter-400";
  src: url("./assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-600";
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-500";
  src: url("./assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter-700";
  src: url("./assets/fonts/Inter-Bold.ttf");
}

/* TODO fix bootstrap p { margin-bottom: 1rem } */

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
}

body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

h3 {
  font-size: 22px !important;
  font-weight: 700;
}
h5 {
  font-size: 14px;
  font-weight: 700;
}

p {
  font-size: 14px;
}

video:focus {
  outline: 0;
  outline-offset: 0;
}

button:focus {
  outline: 0;
  outline-offset: 0;
}

body p {
  margin: 0;
  padding: 0;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
