.advantageList {
    margin-top: 2rem;
    list-style-type: initial;
    padding: 2rem;
    width: 35rem;
    background: #F6F4F0;
    border-radius: 20px;
    .advantageItem {
        margin-left: 2rem;
        margin-bottom: 2.2rem;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: #000000;
        &:last-child{
            margin-bottom: 0;
        }
    }
}