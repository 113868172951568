.pendingReview {
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.header {
  height: 51px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 51px);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      margin-top: 22px;
      font-size: 22px;
      font-weight: 700;
    }
    p {
      margin-top: 16px;
      color: #828282;
      max-width: 343px;
      text-align: center;
    }
  }

  .icon {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff1f4;
    border-radius: 50%;
  }
}
