.breakdown {
  padding: 1.6rem;
  h3 {
    font-size: 2.2rem;
    font-weight: 700;
    span {
      color: var(--main-pink);
    }
  }
  .results {
    opacity: 0;
    animation: slideIn 1.2s ease forwards;
    animation-delay: 0.4s;
    margin-top: 2.4rem;
    border-radius: 1.6rem;
    background-color: var(--gray-bg);
    overflow: hidden;
    .img{
      img {
        width: 100%;
      }
    }
    .content {
      padding: 0 1.6rem 1.6rem;
      margin-top: 1.6rem;
      h5 {
        font-size: 1.4rem;
        font-weight: 700;
        margin-top: 0.8rem;
      }
      .paragraph {
        font-weight: 500;
        font-size: 1.3rem;
      }
      .sign {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--secondary-text);
        margin-top: 0.4rem;
        p {
          font-size: 1.3rem;
        }
      }
    }
  }
  .control {
    padding: 16px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
