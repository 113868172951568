.header {
  height: 51px;
}
.content {
  height: calc(100% - 51px);
}

.initial {
  opacity: 0;
  animation: fadeIn 0.8s ease forwards;
  animation-delay: 0.6s;
  height: 100%;
  padding: 1.6rem;
  .content {
    margin-top: 1.6rem;
    img {
      width: 100%;
    }
    h3 {
      margin-top: 2.4rem;
    }
    p {
      margin-top: 0.8rem;
      font-size: 1.4rem !important;
      font-weight: 400;
      color: #828282 !important;
    }
  }
  .control {
    padding: 16px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
  }
}

.title {
  span {
    color: var(--main-pink);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
