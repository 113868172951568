.container {
  .header {
    background-color: var(--white);
    width: 100%;
    height: 6.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    p {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.4rem;
      text-align: center;
      color: #000000;
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .main {
    padding-top: 2.4rem;
    width: 100%;
    min-height: 100vh;
    background: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    .progressBarWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15.4rem;
      height: 15.4rem;
      border-radius: 50%;
      background: var(--red);
      padding: 1.1rem;
    }
    .progressBarCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 13.2rem;
      height: 13.2rem;
      border: 3px solid #d9d9d9;
      font-weight: 600;
      font-size: 4rem;
      line-height: 4.8rem;
      color: #000000;
    }
    .title {
      margin-top: 1.7rem;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.9rem;
      text-align: center;
      color: #000000;
    }
    .subtitle {
      margin-top: 0.6rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-align: center;
      color: #7d7d7d;
    }
    .startButton {
      margin-top: 2.7rem;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1.8rem 3rem;
      background: var(--black);
      border-radius: 3.5rem;
      border: 0px;
      color: var(--white);
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      img {
        margin-right: 1rem;
      }
    }
    .daysWrapper {
      scrollbar-width: none;
      margin-top: 1.7rem;
      width: 100%;
      overflow: auto;
      white-space: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }
      div:first-child {
        margin-left: 2rem;
      }
    }
    .dayItemActive {
      display: inline-block;
      vertical-align: top;
      min-width: 7.2rem;
      min-height: 4.4rem;
      margin-right: 0.8rem;
      background: var(--red);
      border-radius: 3.5rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      padding: 1.35rem 0rem;
      text-align: center;
      color: var(--white);
      white-space: normal;
    }
    .dayItemComplete {
      display: inline-block;
      vertical-align: top;
      min-width: 7.2rem;
      min-height: 4.4rem;
      margin-right: 0.8rem;
      background: var(--disabled-button-color);
      border-radius: 3.5rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      padding: 1.35rem 0rem;
      text-align: center;
      color: var(--white);
      white-space: normal;
    }
    .dayItem {
      display: inline-block;
      vertical-align: top;
      min-width: 7.2rem;
      min-height: 4.4rem;
      margin-right: 0.8rem;
      background: #f5f5f5;
      border-radius: 3.5rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      padding: 1.35rem 0rem;
      text-align: center;
      color: #0000003d;
      white-space: normal;
    }
    .contentWrapper {
      display: flex;
      align-self: flex-start;
      flex-direction: column;
      width: 100%;
      .contentItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 2rem;
        padding-left: 2rem;
        width: 100%;
        img {
          width: 7.9rem;
          height: 7.9rem;
          border-radius: 2rem;
          margin-right: 1.7rem;
        }
        .infoWrapper {
          display: flex;
          justify-content: start;
          flex-direction: column;
          align-items: flex-start;
        }
        .contentTitle {
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.7rem;
          text-align: center;
          color: #000000;
        }
        .contentSubtitle {
          margin-top: 1.1rem;
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.7rem;
          text-align: center;
          color: #000000;
        }
        &:last-child {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.profileSettingsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 2.5rem 4.4rem 2.5rem;
  .profileSettingsTitle {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.9rem;
    text-align: center;
    color: #000000;
  }
  .logOutButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5.5rem;
    padding: 2.4rem 2.2rem;
    margin-top: 3rem;
    border: 1px solid rgba(125, 125, 125, 0.2);
    border-radius: 20px;
    color: var(--white);
    outline: none;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    background: var(--black);
  }

  .cancelSubscription {
    margin-top: 3.3rem;
    border: 0px;
    background: var(--white);
    outline: 0px;
    color: var(--red);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.advicesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 4.3rem;
  .adviceVideo {
    width: 29.1rem;
    height: 29.1rem;
    border-radius: 20px;
  }
  .adviceTitle {
    margin-top: 1.8rem;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #000000;
  }
  .advicesWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1.8rem;
    .adviceItem {
      padding: 1rem;
      margin-bottom: 1.6rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      background: #f5f4f1;
      border-radius: 10px;
      .adviceIndex {
        margin-right: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 3.7rem;
        min-height: 3.7rem;
        background: var(--red);
        border-radius: 5px;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: #ffffff;
      }
      .adviceName {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #7d7d7d;
      }
    }
  }
}
