.button {
  width: 100%;
  background-color: #ff5a7f;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 16px;
  font-size: 18px;
  color: #fff;
  &:disabled {
    background-color: #ffdee5;
  }
}

.inverted {
  color: #000;
  background-color: #fff;
}
