.cardWithCheckbox {
  text-align: start;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 4vw;
  width: 100%;
  padding: 16px;
  background-color: var(--gray-bg);
  &:active {
    background-color: var(--light-pink);
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.checked {
  background-color: var(--light-pink);
}
