.breakdown {
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    text-align: center;
    max-width: 66%;
    font-weight: 700;
  }

  .chart {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.6rem;
  }

  .focus {
    background-color: var(--light-pink);
    padding: 1.4rem 1.2rem;
    text-align: center;
    border-radius: 1.6rem;
    margin-top: 2.4rem;
    h5 {
      font-size: 1.4rem;
      font-weight: 700;
    }
    p {
      color: var(--secondary-text);
      strong {
        color: var(--main-pink);
      }
    }
  }
  .control {
    padding: 16px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
  }
}

.show1 {
  opacity: 0;
  animation: show 1s ease forwards;
  animation-delay: .6s;
}

.show2 {
  opacity: 0;
  animation: show 1s ease forwards;
  animation-delay: 1s;
}

.show3 {
  opacity: 0;
  animation: show 1s ease forwards;
  animation-delay: 2s;
}

.animation {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: grow 5s ease forwards;
}

@keyframes grow {
  from {
    stroke-dashoffset: 400;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
