// BeforeAfter
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.4rem;
  width: 100%;
  margin-bottom: 12vh;
}
.before_after_group {
  box-sizing: border-box;
  width: calc(100% - 4.8rem);
  height: 25.6rem;
  margin-top: 2.4rem;
  border: 1px solid var(--button-image--border);
  border-radius: 2rem;
}

.img_group {
  height: 20rem;
  img {
    height: 100%;
    width: 100%;
  }
}

.before_after_word_group {
  display: flex;
  justify-content: space-between;
  margin: 1.4rem 4.8rem 2rem 4.8rem;
  font-family: "Inter-400";
  font-size: 1.8rem;
  line-height: 2.2rem;
}

.review_wrapper {
  box-sizing: border-box;
  width: calc(100% - 4.8rem);
  padding: 1.6rem;
  margin-top: 2.4rem;
  height: 26rem;
  background: #f6f4f0;
  border-radius: 2rem;
}

.review_text {
  width: 100%;
  div {
    width: 11.6rem;
    img {
      margin-top: 0.8rem;
      width: 100%;
    }
    p {
      margin: 0;
      font-family: "Inter-700";
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  p {
    margin-top: 0.8rem;
    font-family: "Inter-400";
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
}
