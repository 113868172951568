.layout {
  margin: 0 auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;

}

.content #breakdown{
  border: 1px solid red;
}
