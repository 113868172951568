.page {
  background-color: #737377;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.4rem;
  width: 100%;
  height: 100vh;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  margin-bottom: 3.8rem;
}

.progressBarWrapper {
  background-color: white;
  border-radius: 50%;
  padding: 1.5rem;
  width: 20rem;
  height: 20rem;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.analysisWraper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 8rem;
  .analysisItem {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1.2rem;
  }
  .loaderWrapper {
    width: 2.7rem;
    height: 2.7rem;
    animation: rotating 1s linear infinite;
    margin-right: 1.4rem;
  }
  .analysisText {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #FFFFFF;
  }
  .analysisDone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.7rem;
    height: 2.7rem;
    background: #2F2C28;
    margin-right: 1.4rem;
    border-radius: 48px;
  }
}