.btnAnswer {
  text-align: start;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 4vw;
  padding: 16px;
  background-color: var(--gray-bg);
  color: var(--primairy-text);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.6rem;
  .emoji {
    background-color: #fff;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    p {
      // font-size: 26px;
      margin-left: 3.5px;
      display: inline-block;
      line-height: 26px;
      text-align: center;
      // transform: scale(0.4);
      img {
        margin-left: 1px;
        height: 18px;
        width: 18px;
      }
    }
  }
  &:active {
    background-color: var(--light-pink);
  }
}

