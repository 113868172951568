.questions {
  height: 100%;
}

.header {
  height: 51px;
}
.content {
  height: calc(100% - 51px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .email {
    margin-top: 24px;
    text-align: center;
    .title {
      p {
        margin-top: 12px;
        color: #828282;
      }
    }
    .input {
      margin-top: 40px;
      input {
        background-color: #F5F5F5;
        outline: none;
        border: none;
        font-size: 16px;
        padding: 16px;
        border-radius: 14px;
        width: 100%;
      }
      p {
        text-align: center;
        margin-top: 24px;
        color: #828282;
      }
      .error {
        margin-top: 0;
        color: red;
      }
    }
  }
}
