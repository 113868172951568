.breakdown {
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 478px;
  .background {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.4) 40%,
        #ffffff 80%
      ),
      url("../../../../../assets/img/webp/welldone.webp");
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    .text {
      opacity: 0;
      animation-delay: 0.8s;
      animation: slideIn 1s ease forwards;
      transform: translateY(100px);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: column;
      h3 {
        font-weight: 700;
        color: var(--main-pink);
        font-size: 3.2rem !important;
      }
      p {
        font-weight: 500;
        max-width: 88%;
        font-size: 1.6rem !important;
      }
    }
  }

  .control {
    padding: 16px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
