.result_component__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.paymentTitle {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  color: #000000;
}

.result_component__button {
  bottom: 0;
  border: none;
  outline: none;
  border-radius: 20px;
  width: calc(100% - 4.8rem);
  margin: 1.6rem 2.4rem;
  height: 5.5rem;
  z-index: 2;
  font-family: "Inter-400";
  font-size: 1.8rem;
  line-height: 2.2rem;
  background: var(--red);
  color: var(--white);
}

.result_component__button:disabled {
  background-color: var(--disabled-button-color);
}

.result_component__button:active {
  background-color: var(--disabled-button-color);
}
