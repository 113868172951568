.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 2.4rem;
    h3 {
      font-size: 2.2rem !important;
      font-weight: 700;
    }
  }
  padding: 1.6rem;
  .children {
    margin-top: 2.4rem;
    height: 100%;
    overflow-y: scroll;
    flex: auto;
  }
}

.img {
  width: 100%;
  margin-bottom: 2.4rem;
}
