.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.4rem;
  width: 100%;
  height: 100vh;
  .title {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #000000;
    text-align: center;
    width: 26.7rem;
    span {
      color: #9D4545;
    }
  }
  .subtitle {
    margin-top: 2.4rem; 
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #7D7D7D;
  }
  form {
    padding: 3.3rem 2.4rem 2.4rem 2.4rem;
    width: 100%;
    input {
      width: 100%;
      height: 6.7rem;
      padding: 24px 22px;
      margin-top: 3rem;
      border: 1px solid rgba(125, 125, 125, 0.2);
      border-radius: 20px;
      color: #7D7D7D;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      outline: none;
    }
    .errorInput {
      border: 1px solid #FF0000;
    }
  }
  .communityWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 2.4rem;
    img {
      margin-left: 2rem;
      margin-right: 1.3rem;
    }
    .communityInfo {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #000000;
    }
  }
  .mailboxWrapper {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .description {
    margin-top: 8rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
    color: #9E9E9E;
    span {
      text-decoration: underline;
      cursor: pointer;
      color: #9E9E9E;
    }
  }
}

.submitButton {
  cursor: pointer;
  width: 100%;
  margin-top: 5rem;
  height: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--red);
  border-radius: 20px;
  border: 0px;
  color: white;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.submitButtonLoading {
  background: #C8A4A3;
}

.errorMessage {
  margin-top: 0.5rem;
  align-self: flex-end;
  text-align: right;
  color: #FF0000;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.dotFlashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba( 255,255,255, 0.2);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0s;
}

.dotFlashing::before, .dotFlashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dotFlashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba( 255,255,255, 0.2);
  color:#ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: -0.5s;
}
.dotFlashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba( 255,255,255, 0.2);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0.5s;
}

@keyframes dot-flashing {
  0% {
    background-color: rgba( 255,255,255, 0.2);
  }
  50%, 100% {
    background-color: rgba( 255,255,255, 1);
  }
}