.control {
  padding: 16px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
}

.group {
  padding-bottom: 10rem;
}
