.dropDownItem {
    padding: 0 2.4rem;
    width: 35rem;
    margin-bottom: 1rem;
    height: auto;
    border: 1px solid #7D7D7D;
    border-radius: 20px;
    .dropDownHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        .arrowClose {
            transform: rotate(0deg);
            transition: all 0.3s ease 0s;
        }
        .arrowOpen {
            transform: rotate(180deg);
            transition: all 0.3s ease 0s;
        }
    }
    .dropDownTitle {
        margin-top: 2.3rem;
        margin-bottom: 2.3rem;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #000000;
    }
}
.dropDownDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 146.52%;
    color: #000000;
    max-height: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease 0s, padding-bottom 0.3s ease;
}
.dropDownDescriptionOpen {
    font-weight: 400;
    font-size: 14px;
    line-height: 146.52%;
    color: #000000;
    max-height: 300px;
    padding-bottom: 10px;
    overflow: hidden;
    transition: max-height 0.5s ease 0s, padding-bottom 0.5s ease;
}