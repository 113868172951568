.body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    margin-top: 10rem;
  }
  .mainTitle {
    margin-top: 1rem;
    font-weight: 700;
    font-size: 5rem;
    line-height: 6.1rem;
    text-align: center;
    color: #436A61;
  }
  .subTitle {
    margin-top: 2.8rem;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
    color: #000000;
  }
  .descriptionFirst {
    margin-top: 2.7rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-align: center;
    color: #000000;
  }
  .descriptionSecond {
    margin-top: 1.5rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-align: center;
    color: #000000;
  }
}